'use client'

import { GuestVenue, Maybe } from 'gql/graphql'
import { observer } from 'mobx-react-lite'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { MenuStoreContext } from 'stores/MenuStore'

import AMERICAN_ENGLISH_MESSAGES from '../../lang/dist/en-US.json'
import { localeByVenueCurrency } from './utils'

const BRITISH_ENGLISH_MESSAGES = {}

// We don't need to import British English messages because we handle it with default messages
// We determine the default language server side based on the venue currency
export const LanguageProvider = observer<{
  guestVenue?: Maybe<Pick<GuestVenue, 'currency'>>
  children: ReactNode
}>(({ children, guestVenue }) => {
  const { language } = useContext(MenuStoreContext)
  const determinedLocale = localeByVenueCurrency(language, guestVenue?.currency)
  const [messages, setMessages] = useState(
    determinedLocale === 'en-US'
      ? AMERICAN_ENGLISH_MESSAGES
      : BRITISH_ENGLISH_MESSAGES,
  )

  useEffect(() => {
    async function loadMessages() {
      try {
        const locale = await import(`../../lang/dist/${determinedLocale}.json`)
        setMessages(locale)
      } catch (error) {
        setMessages(BRITISH_ENGLISH_MESSAGES)
      }
    }

    switch (determinedLocale) {
      case 'en':
        setMessages(BRITISH_ENGLISH_MESSAGES)
        break
      case 'en-US':
        setMessages(AMERICAN_ENGLISH_MESSAGES)
        break
      default:
        void loadMessages()
        break
    }
  }, [determinedLocale, language])

  return (
    <IntlProvider
      locale={determinedLocale}
      messages={messages}
      defaultLocale="en"
      onError={() => undefined}
    >
      {children}
    </IntlProvider>
  )
})

LanguageProvider.displayName = 'LanguageProvider'
