import { reportError } from 'lib/bugsnag'
import { appVersion, config } from 'lib/config'
import { useCallback, useRef, useState } from 'react'

/**
 * Converting this hook to a component triggers a reload twice.
 * Make sure to test this scenario if you are planning to make the change.
 */

const useClientVersionSync = () => {
  const [refreshRequired, setRefreshRequired] = useState<boolean>(false)
  const lastFetchTimeRef = useRef<number | null>(null)

  const fetchClientVersion = useCallback(async () => {
    try {
      const response = await fetch('/version')
      const data = await response.json()

      const currentVersion = appVersion
      const deployedVersion = data.appVersion

      if (currentVersion !== deployedVersion) {
        setRefreshRequired(true)
      }

      lastFetchTimeRef.current = Date.now()
    } catch (error) {
      setRefreshRequired(false)
      lastFetchTimeRef.current = Date.now()
      reportError(error)
    }
  }, [])

  const tryFetchClientVersion = useCallback(async () => {
    const shouldFetch =
      !refreshRequired &&
      (lastFetchTimeRef.current === null ||
        Date.now() - lastFetchTimeRef.current >= fetchTimeInterval())

    if (shouldFetch) {
      await fetchClientVersion()
    }
  }, [refreshRequired, fetchClientVersion])

  return {
    refreshRequired,
    tryFetchClientVersion,
  }
}

export default useClientVersionSync

const fetchTimeInterval = (): number => {
  return config.clientVersionFetchIntervalMinutes * 60 * 1000
}
