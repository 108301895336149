export const DEFAULT_TIMEZONE = 'Australia/Melbourne'

export const FLAG_REWARDS_PRICE = 'guest-app-rewards-price'
export const FLAG_MULTIPLE_MODIFIERS = 'multiple-modifiers-fe'
export const FLAG_REWARDS_BALANCE = 'guest-app-rewards-balance'
export const FLAG_ALLOW_POSITIONING_INFO_BLOCKS =
  'allow-positioning-info-blocks'
export const FLAG_NEW_REDEEM_POINTS_VALUE = 'loy-redemption-panel-points'
export const FLAG_POINTS_TO_USE_POLLING = 'loy-points-to-use-polling'
export const FLAG_REWARD_GUEST_VOUCHERS = 'reward-guest-vouchers'
export const FLAG_REWARD_ENABLE_AURA_OAUTH = 'serve-frontend.enable-aura-oauth'
export const FLAG_DISABLE_REORDER_DRINKS =
  'serve-frontend.disable-reorder-drinks-on-venue-landing-page'
export const FLAG_CREW_REVIEWS = 'crew.reviews'
export const FLAG_DRC_LOYALTY = 'serve-frontend.drc-loyalty'
export const FLAG_COASTR_LOYALTY = 'serve-frontend.coastr-loyalty'
export const FLAG_DRC_PHASE_2 = 'serve-frontend.DRC-redeem-loyalty-currency'
export const FLAG_SIMPLE_ORDER_NOTES = 'serve-frontend.simple-order-notes'
export const FLAG_AVC_DISPLAY_PASS_APP =
  'serve-frontend.confirmation-page.avc-venue-pass-app'
export const FLAG_ENABLE_PERSONALISED_ITEMS =
  'frontend.enable-personalised-items'
export const FLAG_ENABLE_EXTERNAL_LOYALTY_DISCOUNTS =
  'serve-frontend.external-loyalty-discounts'
export const FLAG_FAST_FEEDBACK_ENABLED = 'manage-frontend.enable-fast-feedback'
export const RATE_YOUR_EXPERIENCE_ENABLED =
  'manage-frontend.enable-rate-your-experience'
export const ORDER_ACCEPTED_SMS_DISABLED = 'sms.disable-send-order-accepted'
export const ENABLE_AVAILABILITY_AND_WAIT_TIMES =
  'frontend.enable-category-availability'
export const ENABLE_ORDERING_REVAMP = 'serve-frontend.enable-ordering-revamp'

export const ENABLE_NEW_INVOICE_ROUTE =
  'serve-frontend.enable-new-invoice-route'
