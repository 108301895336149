import { YumRoute } from '@mr-yum/frontend-core/dist/services/routes'
import { PaymentState } from 'components/Payment/PaymentController/PaymentController'
import { OrderingType } from 'gql/graphql'
import pick from 'lodash/pick'
import pathToRegexp from 'path-to-regexp'
import qs from 'query-string'

export interface VenueSlug {
  venueSlug: string
}

interface VenueLanding
  extends VenueSlug,
    SeatingContext,
    PlaceContext,
    SheetContext,
    EonXContext {}

interface SeatingContext {
  number?: string | string[]
  area?: string | string[]
}

interface PlaceContext {
  place?: string | string[]
}

interface SheetContext {
  sheet?: string | string[]
}

interface EonXContext {
  eonxCheckInId?: string | string[]
  eonx_venue_id?: string | string[]
}

export type SheetSlug =
  | 'ordering-type'
  | 'order-window'
  | 'table-number'
  | 'address'

interface VenueCollectionSlug {
  vcslug: string
}

interface RegionVenueCollectionSlug extends VenueCollectionSlug {
  vcregion: string
}

interface RegionVenueCollectionLanding
  extends RegionVenueCollectionSlug,
    EonXContext,
    PlaceContext,
    SeatingContext {}

const orderingTypeSlugs = [
  'dine-in',
  'pickup',
  'delivery',
  'menu',
  'catering',
  'in-venue',
  'counter',
] as const

export type OrderingTypeSlug = (typeof orderingTypeSlugs)[number]

export type SupportedOrderingType = Exclude<OrderingType, OrderingType.BillPay>

export const ORDERING_TYPE_SLUG: Record<
  SupportedOrderingType,
  OrderingTypeSlug
> = {
  [OrderingType.DineIn]: 'dine-in',
  [OrderingType.Delivery]: 'delivery',
  [OrderingType.PickUp]: 'pickup',
  [OrderingType.Catering]: 'catering',
  [OrderingType.Counter]: 'counter',
  [OrderingType.InVenue]: 'in-venue',
  [OrderingType.Menu]: 'menu',
}

export const getOrderingSlugFromType = (
  orderingType: OrderingType,
): OrderingTypeSlug => {
  switch (orderingType) {
    case OrderingType.DineIn:
      return 'dine-in'
    case OrderingType.PickUp:
      return 'pickup'
    case OrderingType.Delivery:
      return 'delivery'
    case OrderingType.Catering:
      return 'catering'
    case OrderingType.Counter:
      return 'counter'
    case OrderingType.InVenue:
      return 'in-venue'
    case OrderingType.Menu:
    default:
      return 'menu'
  }
}

export const getOrderingTypeFromSlug = (
  orderingTypeSlug: OrderingTypeSlug | string,
): OrderingType => {
  switch (orderingTypeSlug) {
    case 'dine-in':
      return OrderingType.DineIn
    case 'pickup':
      return OrderingType.PickUp
    case 'delivery':
      return OrderingType.Delivery
    case 'catering':
      return OrderingType.Catering
    case 'in-venue':
      return OrderingType.InVenue
    case 'counter':
      return OrderingType.Counter
    case 'menu':
    default:
      return OrderingType.Menu
  }
}

interface Login extends VenueSlug {
  returnTo?: string
}

interface MenuCategorySlug extends VenueSlug, SeatingContext, PlaceContext {
  orderingTypeSlug: OrderingTypeSlug
  category: string
}

interface MenuCategoryIndex
  extends VenueSlug,
    SeatingContext,
    PlaceContext,
    SheetContext,
    EonXContext {
  orderingTypeSlug: OrderingTypeSlug
}

interface MenuCategoryOrgIndex extends MenuCategoryIndex {
  orgSlug: string
  venueSlugNested: string
}

export interface VenueSlugOrderingType
  extends VenueSlug,
    SeatingContext,
    PlaceContext {
  orderingTypeSlug: OrderingTypeSlug
}

interface Cart extends VenueSlugOrderingType, SheetContext {
  success?: boolean | null
}

interface VenueSlugPaymentState extends VenueSlugOrderingType {
  state: PaymentState[]
}

export type VenueWalletState = ('new' | 'tab')[]

interface VenueSlugWallet extends VenueSlug {
  returnTo?: string
}

interface VenueSlugWalletState extends VenueSlugWallet {
  state: PaymentState[]
  returnTo?: string
}

interface MobileNumber extends Login {
  mobile: string
  retryAfter?: string
}

interface MenuItemSlug extends MenuCategorySlug {
  itemSlug: string
  startWithADrink?: boolean | null
  mvoSearch?: boolean | null
}

interface MenuItemWithSectionSlug extends MenuItemSlug {
  sectionSlug: string
}

interface InvoiceData {
  venueSlug: string
  orderId: string
}

interface TabInvoiceData {
  venueSlug: string
  tabId: string
}

interface Tabs extends VenueSlug {
  tabId: string
}

interface BillData {
  venueSlug: string
  billId: string
  cid?: string | null // collectionId
  rid?: string | null // referenceId
}

interface ConfirmationPage extends VenueSlugOrderingType {
  orderId?: string | null
}

interface MultiVendorConfirmationPage extends VenueSlugOrderingType {
  multiVendorOrderId?: string | null
}
interface OrderHistoryPage extends VenueSlug {
  goBack?: boolean | null
}

interface SocialTabConfirmation
  extends Omit<ConfirmationPage, 'orderingTypeSlug'> {
  tabId: string
  orderId?: string | null
  showBack?: boolean
}

interface SocialTabs extends ConfirmationPage {
  tabId: string
  paymentId?: string
}

interface RewardMembershipData {
  venueSlug: string
  programId: string
}

const getQuery = <T extends {}>(pathData: T, keys: (keyof T)[]) => {
  const query = pick(pathData, keys)
  const emptyQuery = Object.values(query).every((el) => el === undefined)

  if (!emptyQuery) {
    return '?' + qs.stringify(query)
  }

  return ''
}

export const routes = {
  menuItem: {
    label: 'Menu Item',
    href: (pathData) =>
      '/[venueSlug]/[orderingTypeSlug]/[category]/[itemSlug]' +
      getQuery(pathData, ['startWithADrink']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/:orderingTypeSlug/:category/:itemSlug')(
        pathData,
      ) + getQuery(pathData, ['startWithADrink']),
  } as YumRoute<MenuItemSlug>,
  menuItemModal: {
    label: 'Menu Item Modal',
    href: (pathData) =>
      `/[venueSlug]/[orderingTypeSlug]/[category]/?${qs.stringify(pathData)}`,
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/:venueSlug/:orderingTypeSlug/:category/:sectionSlug/:itemSlug',
      )(pathData),
  } as YumRoute<MenuItemWithSectionSlug>,
  menuCategory: {
    label: 'Menu Category',
    href: (pathData) =>
      '/[venueSlug]/[orderingTypeSlug]/[category]' +
      getQuery(pathData, ['number', 'area', 'place']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/:orderingTypeSlug/:category')(
        pathData,
      ) + getQuery(pathData, ['number', 'area', 'place']),
  } as YumRoute<MenuCategorySlug>,
  menuCategories: {
    label: 'Menu Categories',
    href: (pathData) =>
      '/[venueSlug]/[orderingTypeSlug]' +
      getQuery(pathData, [
        'number',
        'area',
        'place',
        'sheet',
        'eonxCheckInId',
        'eonx_venue_id',
      ]),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/:orderingTypeSlug')(pathData) +
      getQuery(pathData, [
        'number',
        'area',
        'place',
        'sheet',
        'eonxCheckInId',
        'eonx_venue_id',
      ]),
  } as YumRoute<MenuCategoryIndex>,
  menuCategoriesOrg: {
    label: 'Menu Categories Org',
    href: (pathData) =>
      '/[venueSlug]/o/[orgSlug]/[venueSlugNested]/[orderingTypeSlug]' +
      getQuery(pathData, [
        'number',
        'area',
        'place',
        'sheet',
        'eonxCheckInId',
        'eonx_venue_id',
      ]),
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/:venueSlug/o/:orgSlug/:venueSlugNested/:orderingTypeSlug',
      )(pathData) +
      getQuery(pathData, [
        'number',
        'area',
        'place',
        'sheet',
        'eonxCheckInId',
        'eonx_venue_id',
      ]),
  } as YumRoute<MenuCategoryOrgIndex>,
  venue: {
    label: 'Venue',
    href: (pathData) =>
      '/[venueSlug]' +
      getQuery(pathData, [
        'number',
        'area',
        'place',
        'sheet',
        'eonxCheckInId',
        'eonx_venue_id',
      ]),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug')(pathData) +
      getQuery(pathData, [
        'number',
        'area',
        'place',
        'sheet',
        'eonxCheckInId',
        'eonx_venue_id',
      ]),
  } as YumRoute<VenueLanding>,
  bookings: {
    label: 'Bookings',
    href: () => '/[venueSlug]/bookings',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/bookings')(pathData),
  } as YumRoute<VenueSlug>,
  history: {
    label: 'Order History',
    href: (pathData) => '/[venueSlug]/history' + getQuery(pathData, ['goBack']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/history')(pathData) +
      getQuery(pathData, ['goBack']),
  } as YumRoute<OrderHistoryPage>,
  cart: {
    label: 'Cart',
    href: (pathData) =>
      '/[venueSlug]/checkout/[orderingTypeSlug]/cart' +
      getQuery(pathData, ['number', 'area', 'place', 'success', 'sheet']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/checkout/:orderingTypeSlug/cart')(
        pathData,
      ) + getQuery(pathData, ['number', 'area', 'place', 'success', 'sheet']),
  } as YumRoute<Cart>,
  login: {
    label: 'Login',
    href: (pathData) => '/[venueSlug]/login' + getQuery(pathData, ['returnTo']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/login')(pathData) +
      getQuery(pathData, ['returnTo']),
  } as YumRoute<Login>,
  loginSsr: {
    label: 'Login',
    href: (pathData) =>
      '/[venueSlug]/login/ssr' + getQuery(pathData, ['returnTo']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/login/ssr')(pathData) +
      getQuery(pathData, ['returnTo']),
  } as YumRoute<Login>,
  membershipValidation: {
    label: 'Membership Validation',
    href: () => '/[venueSlug]/membership',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/membership')(pathData),
  } as YumRoute<VenueSlug>,
  loyaltyMembership: {
    label: 'Loyalty Membership',
    href: () => '/[venueSlug]/membership/loyalty',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/membership/loyalty')(pathData),
  } as YumRoute<VenueSlug>,
  smsCode: {
    label: 'Verify SMS Code',
    href: (pathData) =>
      '/[venueSlug]/login/[mobile]' +
      getQuery(pathData, ['returnTo', 'retryAfter']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/login/:mobile')(pathData) +
      getQuery(pathData, ['returnTo']),
  } as YumRoute<MobileNumber>,
  smsCodeSsr: {
    label: 'Verify SMS Code',
    href: (pathData) =>
      '/[venueSlug]/login/ssr/[mobile]' +
      getQuery(pathData, ['returnTo', 'retryAfter']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/login/ssr/:mobile')(pathData) +
      getQuery(pathData, ['returnTo']),
  } as YumRoute<MobileNumber>,
  payment: {
    label: 'Payment',
    href: () => '/[venueSlug]/checkout/[orderingTypeSlug]/payment',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/checkout/:orderingTypeSlug/payment')(
        pathData,
      ),
  } as YumRoute<VenueSlugOrderingType>,
  pay: {
    label: 'Pay',
    href: () => '/[venueSlug]/checkout/[orderingTypeSlug]/pay',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/checkout/:orderingTypeSlug/pay')(
        pathData,
      ),
  } as YumRoute<VenueSlugOrderingType>,
  paymentState: {
    label: 'Payment',
    href: () => '/[venueSlug]/checkout/[orderingTypeSlug]/payment/[[...state]]',
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/:venueSlug/checkout/:orderingTypeSlug/payment/:state+',
      )(pathData),
  } as YumRoute<VenueSlugPaymentState>,
  confirmation: {
    label: 'Confirmation',
    href: (pathData) =>
      '/[venueSlug]/checkout/[orderingTypeSlug]/confirmation' +
      getQuery(pathData, ['orderId']),
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/:venueSlug/checkout/:orderingTypeSlug/confirmation',
      )(pathData) + getQuery(pathData, ['orderId']),
  } as YumRoute<ConfirmationPage>,
  multiVendorConfirmation: {
    label: 'Multi vendor confirmation',
    href: () =>
      '/[venueSlug]/checkout/[orderingTypeSlug]/m/[multiVendorOrderId]/confirmation',
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/:venueSlug/checkout/:orderingTypeSlug/m/:multiVendorOrderId/confirmation',
      )(pathData),
  } as YumRoute<MultiVendorConfirmationPage>,
  wallet: {
    label: 'Wallet',
    href: (pathData) =>
      '/[venueSlug]/wallet' + getQuery(pathData, ['returnTo']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/wallet')(pathData) +
      getQuery(pathData, ['returnTo']),
  } as YumRoute<VenueSlugWallet>,
  walletState: {
    label: 'Wallet',
    href: (pathData) =>
      '/[venueSlug]/wallet/[[...state]]' + getQuery(pathData, ['returnTo']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/wallet/:state+')(pathData) +
      getQuery(pathData, ['returnTo']),
  } as YumRoute<VenueSlugWalletState>,
  socialTabStart: {
    label: 'Start a Tab',
    href: () => '/[venueSlug]/tabs/start',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/tabs/start')(pathData),
  } as YumRoute<VenueSlug>,
  socialTabManage: {
    label: 'Manage Tab',
    href: () => '/[venueSlug]/tabs/[tabId]/manage',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/tabs/:tabId/manage')(pathData),
  } as YumRoute<Omit<SocialTabs, 'orderingTypeSlug'>>,
  socialTabClose: {
    label: 'Manage Tab',
    href: () => '/[venueSlug]/tabs/[tabId]/close',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/tabs/:tabId/close')(pathData),
  } as YumRoute<Omit<SocialTabs, 'orderingTypeSlug'>>,
  socialTabShare: {
    label: 'Share Tab',
    href: (pathData) =>
      '/[venueSlug]/tabs/[tabId]/share' +
      getQuery(pathData, ['orderId', 'showBack']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/tabs/:tabId/share')(pathData) +
      getQuery(pathData, ['orderId', 'showBack']),
  } as YumRoute<SocialTabConfirmation>,
  socialTabConfirmation: {
    label: 'Tab confirmation',
    href: (pathData) =>
      '/[venueSlug]/checkout/[orderingTypeSlug]/tabs/[tabId]/confirmation' +
      getQuery(pathData, ['orderId', 'paymentId']),
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/:venueSlug/checkout/:orderingTypeSlug/tabs/:tabId/confirmation',
      )(pathData) + getQuery(pathData, ['orderId', 'paymentId']),
  } as YumRoute<SocialTabs>,
  socialTabJoin: {
    label: 'Join Tab',
    href: () => '/[venueSlug]/tabs/[tabId]/join',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/tabs/:tabId/join')(pathData),
  } as YumRoute<Partial<SocialTabs>>,
  tabManage: {
    label: 'Manage my tab',
    href: () => '/[venueSlug]/tabs/[tabId]',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/tabs/:tabId')(pathData),
  } as YumRoute<Tabs>,
  home: {
    label: 'Home',
    href: () => '/',
  } as YumRoute,
  orderAndPay: {
    label: 'Order and pay',
    href: () => '/order-and-pay',
  } as YumRoute,
  mobileMenu: {
    label: 'Mobile menu',
    href: () => '/mobile-menu',
  } as YumRoute,
  pwa: {
    label: 'App',
    href: () => '/pwa/scan',
  } as YumRoute,
  provincialFloors: {
    label: 'Provincial floors',
    href: () => '/provincial-floors',
  } as YumRoute,
  privacyPolicy: {
    label: 'Privacy policy',
    href: () => '/privacy-policy',
  } as YumRoute,
  invoice: {
    label: 'Invoice',
    href: () => '/[venueSlug]/i/[orderId]',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/i/:orderId')(pathData),
  } as YumRoute<InvoiceData>,
  /**
   * Note: If adding PDF routes, remember to update next.config.js,
   * in the `rewrites()` section to redirect the PDF routes to the
   * correct page (it's actually an API call).
   */
  invoicePdf: {
    label: 'Invoice PDF',
    href: () => '/[venueSlug]/i/[orderId]/pdf',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/i/:orderId/pdf')(pathData),
  } as YumRoute<InvoiceData>,
  staffInvoice: {
    label: 'Staff Invoice',
    href: () => '/[venueSlug]/s/[orderId]',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/s/:orderId')(pathData),
  } as YumRoute<InvoiceData>,
  staffInvoicePdf: {
    label: 'Staff Invoice PDF',
    href: () => '/[venueSlug]/s/[orderId]/pdf',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/s/:orderId/pdf')(pathData),
  } as YumRoute<InvoiceData>,
  tabInvoice: {
    label: 'Tab Invoice',
    href: () => '/[venueSlug]/i/t/[tabId]',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/i/t/:tabId')(pathData),
  } as YumRoute<TabInvoiceData>,
  tabInvoicePdf: {
    label: 'Tab Invoice PDF',
    href: () => '/[venueSlug]/i/t/[tabId]/pdf',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/i/t/:tabId/pdf')(pathData),
  } as YumRoute<TabInvoiceData>,
  multiVendorInvoice: {
    label: 'Invoice',
    href: () => '/[venueSlug]/i/m/[orderId]',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/i/m/:orderId')(pathData),
  } as YumRoute<InvoiceData>,
  multiVendorInvoicePdf: {
    label: 'Invoice PDF',
    href: () => '/[venueSlug]/i/m/[orderId]/pdf',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/i/m/:orderId/pdf')(pathData),
  } as YumRoute<InvoiceData>,
  bill: {
    label: 'Invoice',
    href: (pathData) =>
      '/[venueSlug]/bill/[billId]' + getQuery(pathData, ['cid', 'rid']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/bill/:billId')(pathData) +
      getQuery(pathData, ['cid', 'rid']),
  } as YumRoute<BillData>,
  billPdf: {
    label: 'Invoice PDF',
    href: (pathData) =>
      '/[venueSlug]/bill/[billId]/pdf' + getQuery(pathData, ['cid', 'rid']),
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/bill/:billId/pdf')(pathData) +
      getQuery(pathData, ['cid', 'rid']),
  } as YumRoute<BillData>,
  landingPage: {
    label: 'Landing Page',
    href: () => '/c/[vcslug]',
    toPath: (pathData) => pathToRegexp.compile('/c/:vcslug')(pathData),
  } as YumRoute<VenueCollectionSlug>,
  regionLandingPage: {
    label: 'Landing Page',
    href: (pathData) =>
      '/[vcregion]/c/[vcslug]' +
      getQuery(pathData, [
        'number',
        'area',
        'place',
        'eonxCheckInId',
        'eonx_venue_id',
      ]),
    toPath: (pathData) =>
      pathToRegexp.compile('/:vcregion/c/:vcslug')(pathData) +
      getQuery(pathData, [
        'number',
        'area',
        'place',
        'eonxCheckInId',
        'eonx_venue_id',
      ]),
  } as YumRoute<RegionVenueCollectionLanding>,
  sorry: {
    label: 'Sorry',
    href: () => '/sorry',
  } as YumRoute,
  guestProfile: {
    label: 'Guest Profile',
    href: () => '/[venueSlug]/profile',
    toPath: (pathData) => pathToRegexp.compile('/:venueSlug/profile')(pathData),
  } as YumRoute<VenueSlug>,
  editProfile: {
    label: 'Edit account details',
    href: () => '/[venueSlug]/profile/edit',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/profile/edit')(pathData),
  } as YumRoute<VenueSlug>,
  rewardLinkMembership: {
    label: 'Link Membership',
    href: () => '/[venueSlug]/membership/link/[programId]',
    toPath: (pathData) =>
      pathToRegexp.compile('/:venueSlug/membership/link/:programId')(pathData),
  } as YumRoute<RewardMembershipData>,
}
